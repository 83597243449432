import React, { Fragment } from "react"
import imageJcBaey from "./images/jcbaey.jpg"

const authors = {
  jcbaey: {
    image: imageJcBaey,
    text: "Jean-Christophe Baey",
  },
}

const Image = ({ id }) => (
  <img
    className="w-16 h-16 rounded-full mr-4"
    src={authors[id].image}
    alt="Avatar of Author"
  />
)

const Text = ({ id }) => <Fragment >{authors[id].text}</Fragment>

export const Author = {
  Image,
  Text,
  Line: ({ id, text }) => (
    <div className="block flex my-4 items-center">
      <Image id={id} />
      <div className="text-sm md:text-base font-normal">
        <span className="text-gray-800 block"><Text id={id} /></span>
        <span className="text-gray-600 block">{text}</span>
      </div>
    </div>
  ),
}
