import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import renderAst from '../lib/ast-compiler';
import Seo from '../components/seo';
import { TwitterFollowButton, Twitter } from '../components/social';
import { Author } from '../components/author';

const Article = ({ data }) => {
  const { markdownRemark } = data;
  const { frontmatter, htmlAst } = markdownRemark;
  const { title, subtitle, author, date, featuredImage } = frontmatter;

  return (
    <Layout>
      <Seo
        title={title}
        lang="en"
        description={subtitle}
        meta={[
          {
            name: 'og:image',
            content: featuredImage.childImageSharp.fixed.src,
          },
        ]}
      />

      <div className="font-sans">
        <h1 className="mb-0">{title}</h1>
        <p className="subtitle">{subtitle}</p>
        <Author.Line id={author} text={date} />
        {/* "Jan 30 · 9 min read" */}
      </div>
      <div className="mb-16">{renderAst(htmlAst)}</div>

      <div className="mb-16 font-semibold text-blue-600">
        <p>
          <span role="img" aria-label="hand">
            👉
          </span>{' '}
          Don't forget to follow me on <Twitter /> to be notified when new posts are available!{' '}
        </p>{' '}
        <TwitterFollowButton />
      </div>
    </Layout>
  );
};

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      htmlAst
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        author
        subtitle
        featuredImage {
          childImageSharp {
            fixed(width: 1200, height: 628) {
              src
            }
          }
        }
      }
    }
  }
`;

export default Article;